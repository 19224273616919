import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Helmet from 'react-helmet';
import { localizedPath } from 'gatsby-theme-i18n/src/helpers';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import styled from 'styled-components';

import { bubbleSvg } from '../helpers';
import { lightTeal } from '../colors';
import '../globalStyles.css';
import Layout from '../components/Layout';

const SubpageWrapper = styled.div`
  position: relative;
  overflow-x: hidden;
`;

const SubpageContainer = styled.div`
  margin: auto;
`;

const MainSection = styled.div`

`;
const TealBackground = styled.div`
  background-color: var(--color-teal);
`;
const SubpageHeader = styled.div`
  max-width: 1920px;
  margin: auto;
  display: flex;
  padding: 50px 100px 100px 100px;

  @media (max-width: 1370px) {
    padding: 50px;
  }

  @media (max-width: 1023px)  {
    flex-direction: column;
    padding: 50px 25px;
  }

  @media (max-width: 550px) {
    padding: 25px 0 50px 0;
  }
`;

const TextSectionHeader = styled.div`
  width: 50%;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1023px) {
    width: 100%;
    text-align: center;
    margin: auto;
  }
`;

const Headline = styled.h1`
  font-size: 54px;
  white-space: pre-wrap;
  margin-bottom: 6%;
  margin-top: 8%;

  & p {
    margin-bottom: 0;
    margin-top: 0;
  }

  @media (max-width: 1600px) {
    font-size: 44px;
  }

  @media (max-width: 1250px) {
    font-size: 36px;
  }

  @media (max-width: 1023px) {
    margin: auto;
    margin-top: 0;
    width: 80%;
    padding-bottom: 3%;
  }

  @media (max-width: 900px) {
    font-size: 38px;
    width: 90%;
  }

  @media (max-width: 750px) {
    font-size: 37px;
    padding-bottom: 2%;
    width: 95%;
  }

  @media (max-width: 700px) {
    font-size: 28px;
    padding-bottom: 4%;
  }

  @media (max-width: 550px) {
    width: 95%;
    margin: auto;
    padding-bottom: 5%;
  }

  @media (max-width: 450px) {
    font-size: 23px;
  }
`;

const Subtext = styled.div`
  white-space: pre-wrap;
  font-size: 20px;
  margin-top: 0;
  width: 90%;
  margin-bottom: 8%;
  line-height: 1.25;

  & p {
    margin-top: 0;
  }

  & a {
    color: var(--color-dark-grey);
    text-decoration: none;
    transition: all ease 0.3s;
    background-size: auto 175%;
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.6),
      0 4px 0 -3px rgba(52, 191, 201, 0.6),
      inset 0 0 0 0 rgba(52, 191, 201, 0.6);
  }
  
  & a:hover {
    background-position-y: 105%;
    color: var(--color-white);
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.8),
      0 0px 0 2px rgba(52, 191, 201, 0.8),
      inset 0 -1.5em 0 0 rgba(52, 191, 201, 0.8);
  }

  @media (max-width: 1600px) {
    font-size: 18px;
    width: 100%;
  }

  @media (max-width: 1250px) {
    font-size: 17px;
  }

  @media (max-width: 1023px) {
    font-size: 18px;
    width: 85%;
    margin: auto;
    margin-bottom: 5%;
  }

  @media (max-width: 900px) {
    width: 90%;
    font-size: 17px;
  }

  @media (max-width: 750px) {
    width: 95%;
  }

  @media (max-width: 700px) {
    font-size: 16px;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    margin: auto;
    white-space: normal;
    margin-bottom: 8%;
  }

`;

const ImageWrapperHeader = styled.div`
  width: 36%;
  padding-top: 40.57%;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  height: 0;
  margin: auto;

  @media (max-width: 1023px) {
    width: 60%;
    padding-top: 60.86%;
    margin-top: 5%;
  }

  @media (max-width: 900px) {
    width: 75%;
    padding-top: 76.07%;
  }

  @media (max-width: 700px) {
    width: 90%;
    padding-top: 91.28%;
  }

`;

const BackgroundBubble = styled.div`
  background-image: ${bubbleSvg(lightTeal, 45)};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  width: 110%;
  height: 110%;
  left: 19%;
  right: 0;
  top: -5%;
  bottom: 0;

  @media (max-width: 1250px) {
    width: 130%;
  }

  @media (max-width: 1023px) {
    left: -5%;
    top: -12%;
    width: 110%;
    height: 110%;
  }

`;

const ScreenContainerHeader = styled.div`
  background-image: url("${(props) => props.url}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  height: 100%;
  width: 90.5%;
  max-width: 704.33px;
  position: absolute;
  left: 12.2%;
  right: 0;
  top: 0.3%;
  bottom: 0;

  @media (max-width: 1023px) {
    left: 5.2%;
    top: -8%;
  }
`;

const ImageContainerHeader = styled.div`
  background-image: url(/img/mockup_tablet.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 7%;
  right: 0;
  top: 0;
  bottom: 0;

  @media (min-width: 1921px) {
    max-width: 774px;
  }

  @media (max-width: 1023px) {
    left: 0;
    top: -8.2%;
  }
`;

const TrustFactContainer = styled.div`
  max-width: 1920px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: auto;
  font-size: 18px;
  padding-top: 5%;

  @media (max-width: 1250px) {
    font-size: 17px;
    max-width: 95%;
  }

  @media (max-width: 1023px) {
    font-size: 16px;
  }

  @media (max-width: 900px) {
    font-size: 15px;
    padding-bottom: 5%;
    padding-top: 8%;
  }

  @media (max-width: 550px) {
    font-size: 14px;
  }
`;

const TrustFactSection = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  padding-bottom: 5%;

  @media (max-width: 550px) {
    width: 100%;
    padding-bottom: 8%;
  }
`;

const TrustFactIcon = styled.div`
  background-image: url("${(props) => props.url}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 60px;

  @media (max-width: 900px) {
    height: 50px;
  }

  @media (max-width: 400px) {
    height: 40px;
  }
`;

const TrustFactHeadline = styled.p`
  font-weight: 700;
`;

const TrustFactText = styled.p`
  max-width: 60%;
  margin: auto;
  margin-top: 0;

  @media (max-width: 900px) {
    max-width: 80%;
  }

  @media (max-width: 400px) {
    max-width: 95%;
  }
`;

const InfoContainer = styled.div`
  max-width: 1920px;
  margin: auto;
  padding: 80px 100px 0 100px;

  @media (max-width: 1370px) {
    padding: 70px 50px 0 50px;
  }

  @media (max-width: 1023px) {
    padding: 50px 25px 0 25px;
  }

  @media (max-width: 550px) {
    padding: 50px 0 0 0;
  }
`;

const InfoSection1 = styled.div`
  color: var(--color-dark-grey);
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;

  & img {
    width: 45%;
    height: 100%;
    margin: auto;
    margin-right: 0;

    @media (max-width: 1150px) {
      width: 49%;
    }

    @media (max-width: 1023px) {
      width: 70%;
      margin: auto;
      margin-top: 5%;
    }
  
    @media (max-width: 700px) {
      width: 90%;
    }
  
    @media (max-width: 550px) {
      width: 95%;
    }
  }

  @media (max-width: 1023px) {
    flex-direction: column;
  }

  @media (max-width: 550px) {
    padding-bottom: 50px;
  }
`;

const InfoTextContainer = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1023px) {
    width: 100%;
  }
`;

const InfoHeadline = styled.h2`
  font-weight: 900;
  font-size: 32px;
  color: var(--color-dark-grey);
  margin-bottom: 3%;
  margin-top: 0;

  @media (max-width: 1250px) {
    font-size: 24px;
  }

  @media (max-width: 1023px) {
    font-size: 22px;
  }

  @media (max-width: 900px) {
    font-size: 20px;
  }

  @media (max-width: 700px) {
    font-size: 18px;
  }

  @media (max-width: 550px) {
    font-size: 16px;
    width: 95%;
    margin: auto;
  }
`;

const InfoText = styled.div`
  white-space: pre-wrap;
  font-size: 18px;
  line-height: 1.25;

  & a {
    color: var(--color-dark-grey);
    text-decoration: none;
    transition: all ease 0.3s;
    background-size: auto 175%;
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.6),
      0 4px 0 -3px rgba(52, 191, 201, 0.6),
      inset 0 0 0 0 rgba(52, 191, 201, 0.6);
  }
  
  & a:hover {
    background-position-y: 105%;
    color: var(--color-white);
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.8),
      0 0px 0 2px rgba(52, 191, 201, 0.8),
      inset 0 -1.5em 0 0 rgba(52, 191, 201, 0.8);
  }

  & ul {
    @media (max-width: 450px) {
      padding-left: 20px;
    }
  }

  @media (max-width: 1250px) {
    font-size: 17px;
  }

  @media (max-width: 1023px) {
    font-size: 16px;
  }

  @media (max-width: 900px) {
    font-size: 15px;
  }

  @media (max-width: 550px) {
    font-size: 14px;
    white-space: normal;
    width: 95%;
    margin: auto;
  }

`;

const SlideContainer = styled.div`
  width: 50%;
  align-content: center;

  & button {
    padding: 7px 11px;
  }
  
  & ul {
    padding-inline-start: 0;
  }

  & .react-slideshow-container+ul.indicators {
    margin-top: 10px;
    margin-bottom: 0;
  }

  & .react-slideshow-container+ul.indicators li {
    width: 33px;
    padding: 0;
  }

  & .react-slideshow-container + ul.indicators .each-slideshow-indicator::before {
    background: var(--color-teal);
    width: 9px;
    height: 7px;
    position: relative;
  }

  @media (max-width: 1023px) {
    padding-top: 8%;
    width: 100%;
  }

`;

const Image = styled.div`
  background-image: url("${(props) => props.url}");
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  width: 35vw;
  height: calc(35vw / 16 * 9);
  max-width: 750px;
  max-height: calc(750px / 16 * 9);


  @media (max-width: 1250px) {
    width: 40vw;
    height: calc(40vw / 16 * 9);  
  }

  @media (max-width: 1023px ) {
    width: 65vw;
    height: calc(65vw / 16 * 9);
  }

  @media (max-width: 750px) {
    width: 75vw;
    height: calc(75vw / 16 * 9);
  }

  @media (max-width: 550px) {
    width: 95vw;
    height: calc(95vw / 16 * 9);
  }

`;

const CustomerReferencesContainerHeader = styled.div`
  max-width: 1920px;
  margin: auto;
  background-color: var(--color-teal);
  padding: 50px 80px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1370px) {
    padding: 20px 40px;
  }

  @media (max-width: 1023px) {
    padding: 20px 25px;
  }

  @media (max-width: 900px) {
    padding: 15px 5px;
  }

  @media (max-width: 700px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: 450px) {
    justify-content: space-around;
  }
`;

const CustomerImage = styled.figure`
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: 80%;

    @media (max-width: 1250px) {
      width: 110%;
    }

    @media (max-width: 1023px) {
      width: 135%;
    }

    @media (max-width: 900px) {
      width: 160%;
    }

    @media (max-width: 350px) {
      width: 200%;
    }
  }

  @media (max-width: 700px) {
    width: 15%;
    height: 50px;
  }

  @media (max-width: 550px) {
    width: 22%;
  }

  @media (max-width: 450px) {
    width: 26%;
  }

  @media (max-width: 350px) {
    width: 22%;
  }
`;

const ContactPageForwarding = styled.div`
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  padding: 5% 8%;
  background-color: var(--color-light-yellow);
  width: 42%;
  margin: auto;
  margin-top: 10%;
  margin-bottom: 10%;
  text-align: center;
  border-radius: 100px;
  position: relative;
  white-space: pre-wrap;
  font-size: 17px;
  line-height: 1.25;

  @media (min-width: 1921px) {
    padding: 3% 3%;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  @media (max-width: 1500px) {
    width: 50%;
  }

  @media (max-width: 1250px) {
    font-size: 16px;
    margin-top: 12%;
  }

  @media (max-width: 1023px) {
    width: 60%;
    margin-top: 15%;
  }

  @media (max-width: 900px) {
    padding: 5% 11%;
    font-size: 15px;
  }

  @media (max-width: 700px) {
    font-size: 15px;
    width: 100%;
    border-radius: 0;
    padding: 0;
    padding-bottom 7%;
    margin-top: 20%;
    margin-bottom: 0;
  }

  & p {
    margin: auto;
    color: var(--color-dark-grey);
    padding-bottom: 7%;
    font-weight: 700;

    @media (max-width: 700px) {
      padding: 7% 15%;
    }

    @media (max-width: 430px) {
      padding: 7% 12%;
    }
  }
`;

const RequestDemoButton = styled.button`
  width: 230px;
  color: var(--color-dark-grey);
  margin: auto;

  @media (max-width: 900px) {
    font-size: 17px;
    width: 220px;
  }

  @media (max-width: 700px) {
    font-size: 16px;
    width: 190px;
  }

  @media (max-width: 440px) {
    font-size: 15px;
    width: 180px;
  }
`;

const IconPageForwarding = styled.div`
  background-image: url(/img/icon_contact_component.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 80%;
  width: 25%;
  position: absolute;
  left: 85%;
  right: 0;
  top: -38%;
  bottom: 0;

  @media (min-width: 1921px) {
    left: 92%;
    top: -45%;
  }

  @media (max-width: 1250px) {
    width: 30%;
    height: 70%;
    left: 84%;
    top: -42%;
  }

  @media (max-width: 1023px) {
    left: 86%;
    top: -35%;
  }

  @media (max-width: 900px) {
    left: 84%;
  }

  @media (max-width: 700px) {
    height: 50%;
    width: 24%;
    top: -30%;
    left: 0%;
  }

  @media (max-width: 550px) {
    top: -25%;
  }

  @media (max-width: 430px) {
    height: 31%;
    width: 23%;
    top: -16%;
    left: -1%;
  }
`;

export default class ReferencesSubpages extends Component {
  static propTypes = {
    pageContext: PropTypes.object,
    data: PropTypes.object,
  };

  // eslint-disable-next-line class-methods-use-this
  renderContent(page) {
    const id = page?.referencesSubpage?.id || Math.random();
    const submenu = page?.submenus?.edges?.find((menu) => menu?.node?.frontmatter?.submenuId === page?.referencesSubpage?.frontmatter?.submenuId)?.node;

    const properties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: false,
    };

    return (
      <SubpageContainer key={id}>
        <MainSection>
          <TealBackground>
            <SubpageHeader>
              <TextSectionHeader>
                <Headline>
                  <MDXRenderer>{page?.referencesSubpage?.frontmatter?.subpageHeadline}</MDXRenderer>
                </Headline>
                <Subtext>
                  <MDXRenderer>{page?.referencesSubpage?.frontmatter?.referencesSubpageSubtext}</MDXRenderer>
                </Subtext>
              </TextSectionHeader>
              <ImageWrapperHeader>
                <BackgroundBubble />
                <ScreenContainerHeader url={page?.referencesSubpage?.frontmatter?.subpageHeaderImage} />
                <ImageContainerHeader />
              </ImageWrapperHeader>
            </SubpageHeader>
          </TealBackground>
          <InfoContainer>
            <InfoSection1>
              <InfoTextContainer>
                <InfoHeadline>{page?.referencesSubpage?.frontmatter?.textSectionHeadline1}</InfoHeadline>
                <InfoText>
                  <MDXRenderer>{page?.referencesSubpage?.frontmatter?.textSectionText1}</MDXRenderer>
                </InfoText>
              </InfoTextContainer>
              <SlideContainer>
                <Slide {...properties}>
                  {page?.referencesSubpage?.frontmatter?.sliderImages1?.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Image key={index} url={item.referencesSubpageSliderImage1} />
                  ))}
                </Slide>
              </SlideContainer>
            </InfoSection1>
          </InfoContainer>
          <TealBackground>
            <CustomerReferencesContainerHeader>
              {page?.referencesSubpage?.frontmatter?.customerReferenceImagesHeader.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <CustomerImage key={index}>
                  <img src={item.customerReferenceImageHeader} alt={item.altAttributeCustomerImageHeader} loading="lazy" />
                </CustomerImage>
              ))}
            </CustomerReferencesContainerHeader>
          </TealBackground>
          <TrustFactContainer>
            {page?.referencesSubpage?.frontmatter?.trustFactsReferencesSubpage.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TrustFactSection key={index}>
                <TrustFactIcon url={item?.trustFactIcon} />
                <TrustFactHeadline>{item?.trustFactHeadline}</TrustFactHeadline>
                <TrustFactText>{item?.trustFactText}</TrustFactText>
              </TrustFactSection>
            ))}
          </TrustFactContainer>
          <ContactPageForwarding>
            <MDXRenderer>{submenu?.frontmatter?.linkToContactText}</MDXRenderer>
            <RequestDemoButton
              type="button"
              onClick={() => {
                navigate(localizedPath({
                  locale: `${submenu?.fields?.locale}`,
                  path: '/contact',
                  defaultLang: 'de',
                  prefixDefault: false,
                }));
              }}
            >
              { submenu?.frontmatter?.buttonText || 'BUTTONTEXT NOT FOUND' }
            </RequestDemoButton>
            <IconPageForwarding />
          </ContactPageForwarding>
        </MainSection>
      </SubpageContainer>
    );
  }

  render() {
    const { pageContext, data } = this.props;
    const page = data || {};

    let canonicalLinkPrefix;

    if (pageContext.locale === 'en') {
      canonicalLinkPrefix = 'https://presono.com/en';
    } else {
      canonicalLinkPrefix = 'https://presono.com';
    }

    return (
      <Layout currentPath={pageContext.originalPath} submenuId={page?.referencesSubpage?.frontmatter?.submenuId}>
        <Helmet>
          <title>{`${data?.referencesSubpage?.frontmatter?.titleMetaTag} | presono`}</title>
          <meta
            name="description"
            content={data?.referencesSubpage?.frontmatter?.snippetTextSubpage}
          />

          <link rel="canonical" href={canonicalLinkPrefix + pageContext.originalPath} />

          <meta property="og:title" content={data?.referencesSubpage?.frontmatter?.titleMetaTag} />
          <meta property="og:description" content={data?.referencesSubpage?.frontmatter?.snippetTextSubpage} />
          <meta property="og:image" content={`https://presono.com${data?.referencesSubpage?.frontmatter?.subpageHeaderImage}`} />
          <meta name="twitter:title" content={data?.referencesSubpage?.frontmatter?.titleMetaTag} />
          <meta name="twitter:description" content={data?.referencesSubpage?.frontmatter?.snippetTextSubpage} />
          <meta name="twitter:image" content={`https://presono.com${data?.referencesSubpage?.frontmatter?.subpageHeaderImage}`} />
        </Helmet>
        <SubpageWrapper className="off-white-background">
          { this.renderContent(page) }
        </SubpageWrapper>
      </Layout>
    );
  }
}

export const query = graphql`
query SubpageQuery($title: String!, $locale: String!) {
  referencesSubpage: mdx(
    fields: {locale: {eq: $locale}}
    frontmatter: {title: {eq: $title}, layout: {eq: "referencesSubpage"}}
  ) {
    frontmatter {
      title
      titleMetaTag
      snippetTextSubpage
      submenuId
      subpageId
      submenuSubheadline
      subpageHeadline
      referencesSubpageSubtext
      subpageHeaderImage
      textSectionHeadline1
      textSectionText1
      sliderImages1 {
        referencesSubpageSliderImage1
      }
      customerReferenceImagesHeader {
        customerReferenceImageHeader
        altAttributeCustomerImageHeader
      }
      trustFactsReferencesSubpage {
        trustFactIcon
        trustFactHeadline
        trustFactText
      }
      contactHeadline
      contactSubtext
      contactbuttonText
      contactInfoTextButton
    }
    id
    slug
    fields {
      locale
    }
  }
  submenus: allMdx(
    sort: { order: ASC, fields: [frontmatter___submenuId] }
    filter: {
      frontmatter: { layout: {eq: "submenu"} }
      fields: {locale: {eq: $locale}}
    }
  ) {
    edges {
      node {
        frontmatter {
          submenuId
          title
          linkToContactText
          buttonText
        }
        fields {
          locale
        }
      }
    }
  }
  referencesSubpages: allMdx(
    sort: { order: ASC, fields: [frontmatter___submenuId, frontmatter___subpageId] }
    filter: {
      frontmatter: { layout: {eq: "referencesSubpage"} }
      fields: {locale: {eq: $locale}}
    }
  ) {
    edges {
      node {
        frontmatter {
          title
          submenuId
          subpageId
          submenuSubheadline
        }
        slug
        id
        fields {
          locale
        }
      }
    }
  }
}
`;
